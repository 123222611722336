<template>
  <v-dialog :value="value" @input="close" max-width="600px">
    <v-card>
      <!-- Title -->
      <v-card-title>
        <h3>{{ $t("studies.components.link.title") }}</h3>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row no-gutters>
            <!-- Components select -->
            <v-col cols="12">
              <SelectComponent
                :value="form.components"
                :is-multiple="true"
                @input="onInputComponent"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <ButtonBarFormDialog
          class="mx-3"
          :is-loading-btn-save="isLoadingBtn.btnSave"
          @submit="customSubmit"
          @cancel="close"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialog";
import formRules from "@/mixins/formRules";

export default {
  name: "ProductComponentLinkDialog",

  mixins: [dialogMixin, formRules],

  components: {
    SelectComponent: () => import("@/components/Common/Inputs/SelectComponent"),
    ButtonBarFormDialog: () =>
      import("@/components/Common/Buttons/ButtonBarFormDialog"),
  },

  props: {
    study: { type: Object, default: () => null },
  },

  data() {
    return {
      isLoadingBtn: { btnSave: false },

      defaultForm: {
        components: [],
      },
    };
  },

  methods: {
    customSubmit() {
      if (this.$refs.form.validate()) {
        this.isLoadingBtn.btnSave = true;

        this.shortcutFormObjects(["components"]);
        this.submitAdd();
      }
    },

    submitAdd() {
      this.$http
        .put(`/studies/${this.study.id}/link-components`, this.form, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.$store.commit(
            "toast/showSuccess",
            this.$t("studies.components.link.success")
          );
          this.close(true);
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingBtn.btnSave = false;
        });
    },

    onInputComponent(val) {
      this.form.components = val;
    },
  },
};
</script>

<style scoped></style>
